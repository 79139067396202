<template>
  <div id="subscription-index">
    <div class="mt-5 d-flex">
      <h1 class="font-weight-medium">{{ product.title }}</h1>
      <v-icon
        color="primary"
        class="ml-5"
      >
        mdi-check-circle
      </v-icon>
    </div>
    <v-chip class="mt-2" color="backgroundLight">
      {{ $t('newDeals.productInfo.chip') }}
    </v-chip>
    <v-chip class="mt-2 ml-2" color="backgroundLight">
      {{ $t(`assetSubtype.${product.assetSubType}`) }}
    </v-chip>
    <component
      class="mt-5"
      :is="step"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Subscription',
  components: {
    Experiences: lazyLoad('views/Subscription/Experiences'),
    Amount: lazyLoad('views/Subscription/Amount'),
    AdequacyCheck: lazyLoad('views/Subscription/AdequacyCheck'),
    Costs: lazyLoad('views/Subscription/Costs'),
    Signing: lazyLoad('views/Subscription/Signing'),
    Congratulation: lazyLoad('views/Subscription/Congratulation'),
  },
  computed: {
    ...mapGetters('subscription', [
      'index',
      'step',
    ]),
    ...mapGetters('assets', {
      products: 'all',
    }),
    product() {
      return this.products.find((item) => item.id === this.$route.params.product);
    },
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    ...mapActions('subscription', [
      'reset',
    ]),
  },
};
</script>
